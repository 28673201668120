import React from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { useAxyaTracking } from "@next/hooks/useAxyaTracking";

import { ContactUsFormProps, ContactUsFormValues } from "./contact-us.types";
import ContactUsFormBase from "./contact-us-form.base";

import { getValidationSchema } from "./contact-us.definitions";
import { workspaceNextActions } from "../../redux";
import { useParams } from "react-router-dom";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../../modals/types";

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  formTitle,
  hasFormTitle,
  hasSubjectAndMsg,
  helpSubject,
  label,
  footerText,
  footerLinkText,
  iconName,
  isAirbusSupportModal,
  supplierDetails,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const axyaTracking = useAxyaTracking();

  const initialValues: ContactUsFormValues = {
    subject: isAirbusSupportModal
      ? supplierDetails
      : "" || (helpSubject as string),
    msg: "",
  };

  const airbusSupportInitialValues: any = {
    subject: isAirbusSupportModal
      ? supplierDetails
      : "" || (helpSubject as string),
    msg: "",
    attachments: [],
  };
  const company = useParams<{ companyId: string }>();

  const validationSchema = getValidationSchema(hasSubjectAndMsg as boolean);

  const handleContactUs = ({
    msg,
    subject,
    attachments,
  }: ContactUsFormValues) => {
    let payload = { msg, subject };
    if (isAirbusSupportModal && company?.companyId) {
      dispatch(
        vendorManagementActions.sendSupportMessageRequest({
          companyId: company.companyId,
          data: { subject, msg, attachments },
          onSuccess: () => {
            dispatch(
              modalsActions.closeModal(WorkspaceModalTypes.CONTACT_US_MODAL)
            );
          },
        })
      );
    } else {
      dispatch(workspaceNextActions.contactUsRequest({ ...payload }));
    }
    axyaTracking({
      app: "axya-app",
      scout_category: "contact-us",
      scout_feature_name: `${
        hasSubjectAndMsg ? "contact-support" : "give-you-feedback"
      }`,
    });
  };

  return (
    <>
      <Formik
        render={(props) => (
          <ContactUsFormBase
            {...props}
            formTitle={formTitle}
            hasFormTitle={hasFormTitle}
            hasSubjectAndMsg={hasSubjectAndMsg}
            label={label}
            footerText={footerText}
            footerLinkText={footerLinkText}
            iconName={iconName}
            isAirbusSupportModal={isAirbusSupportModal}
          />
        )}
        initialValues={
          isAirbusSupportModal ? airbusSupportInitialValues : initialValues
        }
        onSubmit={handleContactUs}
        validationSchema={validationSchema}
      />
    </>
  );
};

export default ContactUsForm;
